.loader:empty {
    position: absolute;
    top: 47%;
    left: 47%;
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid $twzzraccent;
    border-radius: 50%;
    animation: loading_animation 1.1s infinite linear;
}

@keyframes loading_animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
