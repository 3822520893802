@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "./exports.module";

// @import "../.."
$theme-colors: (
    "primary":
        $twzzraccent,
    "teal":
        #1bbde3,
    "gray":
        #071838,
);

$pagination-active-bg: $twzzraccent;
$pagination-color: $twzzraccent;
$pagination-hover-color: $twzzraccent;
$dropdown-link-color: $twzzraccent;
$link-color: $twzzraccent;
$dropdown-link-active-bg: $twzzraccent;
$enable-gradients: true;
$enable-shadows: true;

.badge-purple {
    color: white;
    background-color: purple;
}

.badge-orange {
    color: white;
    background-color: #ff8c00;
}


@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
      .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
      .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
      .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }
  
      .border#{$infix}-top-0 {    border-top: 0 !important; }
      .border#{$infix}-right-0 {  border-right: 0 !important; }
      .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
      .border#{$infix}-left-0 {   border-left: 0 !important; }
  
      .border#{$infix}-x {
        border-left: $border-width solid $border-color !important;
        border-right: $border-width solid $border-color !important;
      }
  
      .border#{$infix}-y {
        border-top: $border-width solid $border-color !important;
        border-bottom: $border-width solid $border-color !important;
      }
      // support of .border-sm (-md, -lg, -xl)
      .border#{$infix} {
        border-top: $border-width solid $border-color !important;
        border-bottom: $border-width solid $border-color !important;
        border-left: $border-width solid $border-color !important;
        border-right: $border-width solid $border-color !important;
      }
      .border#{$infix}-0 {
        border-top: 0 !important;
        border-bottom: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
      }
    }
  }

@import "../../node_modules/bootstrap/scss/bootstrap";

@media only screen and (min-width: 1000px) {
    .navbar-padding {
        padding-left: 150px;
        padding-right: 150px;
    }
}

html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    letter-spacing: 0.11px;
    font-size: 15px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: black;
    z-index: 0;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    line-height: 1.5;
    text-align: left;
}

#root {
    display: grid;
    grid-template-rows: 0.5fr;
    min-height: 100vh;
}

.auth {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    height: 100% !important;
    width: auto;
    &-svg {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}
// .auth

.fa:hover {
    opacity: 0.7;
}

@media only screen and (min-width: 780px) {
    .tw-overlap {
        position: absolute;
        bottom: 0px;
        right: 30px;
    }
    #carouselExampleControls {
        display: block;
    }
}

@media only screen and (max-width: 780px) {
    .overlap-img {
        display: none;
    }
    .overlap {
        padding: 2em;
    }
    #carouselExampleControls {
        display: none;
    }
}

.montserrat {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
}

// .tw-hero

section {
    padding-top: 3em;
    padding-bottom: 3em;
}


@import "./twzzrstrap.scss";
@import "./loader.scss";

.fYvZuV {
    @extend .tw-heading,
        .montserrat,
        .text-primary;
}
