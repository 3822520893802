.tw- {
    @media only screen and (max-width: 600px) {
        &hideOnMobile {
            display: none;
        }
        &showOnMobile {
            display: block;
        }
    }
    &hero {
        border-radius: 0px;
        background-repeat: no-repeat;
        width: auto;
        height: 100vh;
        background-position: -40em;
        &-heading {
            font-size: 32.5px !important;
            font-weight: bold !important;
            line-height: 1.31 !important;
            letter-spacing: 0.33px !important;
        }
        &-button:hover {
            text-decoration: none;
            color: white;
        }
        &-button {
            border-radius: 21.5px;
            font-size: 19px;
            line-height: 0.73;
            padding: 12px
                53px;
        }
    }
    &main-mini {
        /* Mini boxes in Main page. */
        opacity: 0.8;
        height: 20em;
        box-sizing: border-box;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);

        &-title {
            font-size: 2em; 
            font-weight: bold;
        }
        &-body {
            font-size: 15px;
            font-size: 17px;
            line-height: 1.36;
            letter-spacing: 0.11px;
            color: #ffffff;
        }
    }
    &logo {
        /* To  keep logo in size */
        width: 108.2px;
        height: 21.7px;
    }
    &offers {
        /* TODO Check if this can be minimised */
        border-radius: 6px;
        border: solid
            0.5px
            #707070;
        background-color: #ffffff;
        font-size: 12.5px;
        line-height: 1.6;
        text-align: left;
        &-pin {
            /* Design feature: like in Offers */
            background-color: $twzzraccent;
            width: 14px;
            height: 14px;
            border-radius: 50%;
        }
    }
    &label {
        /* Label for Forms */
        font-weight: 600;
        letter-spacing: 0.11px;
        font-size: 13px;
        line-height: 1.36;
    }
    &nav-link {
        /* For navbar links */
        font-size: 15px;
        font-weight: 600;
        line-height: 0.8;
        color: $twzzraccent;
        margin-left: 37.5px;
    }
    &main-text {
        letter-spacing: 0.11px;
        font-size: 15px;
        margin-top: 42px;
        color: #767f8f;
    }
    &heading {
        font-size: 17.5px !important;
        font-weight: 700 !important;
        line-height: 2.43;
        
    }

    @media only screen and (max-width: 600px) {
        &hero {
            background-position-x: -113em;
            height: 90vh;
        }
    }
}
